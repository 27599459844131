import React, {Component} from 'react';
import MetricGroup from './MetricGroup';
import SelectLocation from './SelectLocation';
import { sourceURL } from '../helpers/formatters';
import Location from '../helpers/locations';
import ErrorMessage from './ErrorMessage';
import { compareByName } from '../helpers/comparisons';

export default class Dashboard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: null,
      region: null,
      rootUrl: props.rootUrl,
      geoLocationType: props.geoLocationType,
      geoLocation: props.geoLocation,
      locations: [],
      year: props.year,
      report: props.report,
      title: null,
      loading: true,
      error: false
    }
  }

  fetchData() {
    let url = sourceURL(this.state.rootUrl, this.state.geoLocationType, this.state.geoLocation, this.state.year, this.state.report, this.props.selectLocationRegion);
    fetch(url)
      .then(response => response.json())
      .then(data => {
        this.setState({
          data: data.data,
          region: data.geo_location,
          year: data.year,
          locations: data.locations,
          title: data.report,
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          error: true
        })
      })
  }

  updateLocation = (location) => {
    // {value: 'county, Blue Earth', label: 'Blue Earth'}

    let [geoLocationType, geoLocation] = Location.unpack(location.value);
    this.setState({
      geoLocationType: geoLocationType,
      geoLocation: geoLocation,
      loading: true
    }, () => this.fetchData())
  }

  title = () => {
    let reportName = this.state.title || "Goals"
    return(`${ reportName } for ${ this.state.geoLocation } `)
  }

  componentDidMount() {
    this.fetchData();
  }

  filterMetricGroup = (metricGroup) => {
    // no filter
    if (!this.props.metricGroup) return true

    // match filter
    if (this.props.metricGroup && this.props.metricGroup === metricGroup.name) return true

    // no match
    return false
  }

  render() {
    if (this.state.error) {
      return (<ErrorMessage />)
    }
    if (!this.state.data || !this.state.data.metric_groups) {
      return(<div></div>);
    }

    let metricGroups = this.state.data.metric_groups.sort(compareByName).map(
      (metricGroup, index) => {
        if (this.filterMetricGroup(metricGroup)) {
          return(
            <MetricGroup groupData={metricGroup} key={index} loading={this.state.loading} />
          )
        }
        return null
      }
    )

    return(
      <div className="mado-dashboard">
        <div className="mado-dashboard__header">
          { this.state.error && ErrorMessage }

          { !this.props.metricGroup &&
            <h2>{this.title()}</h2>
          }

          { this.props.selectLocation && 
            <div className="mado-dashboard__header__select-region">
            <SelectLocation locations={this.state.locations.regions} updateLocationFunction={this.updateLocation} stateOption={!this.props.selectLocationRegion} />
            </div>
          }
        </div>
        <div className="mado-dashboard__metric-groups">
          { metricGroups }
        </div>
      </div>
    )
  }
}
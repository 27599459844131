export function describeTrend(data, trendGoal) {
  let latest = data[data.length - 1]
  let previous = data[data.length - 2]
  let average = getAverage(data)

  if (latest === previous && latest === average) return null

  if (trendGoal === 'up') {
    if (latest > previous && latest > average) return "improving"
    if (latest > previous) return "improving slightly"
    if (latest < previous && latest < average) return "needs work"
    if (latest < previous) return "needs work"
  }
  if (latest > previous && latest > average) return "needs work"
  if (latest > previous) return "needs work"
  if (latest < previous && latest < average) return "improving"
  if (latest < previous) return "improving slightly"

}

function getAverage(data) {
  return (getSum(data) / data.length)
}

function getSum(data) {
  return data.reduce((total, currentValue) => total + currentValue)
}
export function parameterizeString(str) {
  return str.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-");
}

export function unitValue(measurements, unitSize, precision) {
  if (measurements.length < 1) return null
  let mostRecentMeasurement = measurements.sort((a, b) => b.year - a.year)[0]
  let value = mostRecentMeasurement?.value
  let formattedValue = value;
  if (unitSize && unitSize > 0) {
    formattedValue = value / unitSize;
  }
  if (typeof precision !== 'undefined' && !isNaN(precision)) {
    formattedValue = formattedValue.toFixed(precision)
  }
  if (formattedValue >= 1000) {
    formattedValue = numberWithCommas(formattedValue);
  }
  return formattedValue;
}

export function sourceURL(rootUrl, geoLocationType, geoLocation, year, report=null, selectLocationRegion=null) {
  let url = new URL(`${rootUrl}/${year}/${geoLocationType}/${encodeURI(geoLocation)}.json`);
  if (!!report) {
    url.searchParams.append("report", report)
  }
  if (!!selectLocationRegion) {
    url.searchParams.append("select_location_region", selectLocationRegion)
  }
  return url
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

import React, { useState, useEffect } from 'react'
import Metric from './Metric'
import { sourceURL } from '../helpers/formatters'

function MetricView(props) {

  const [metric, setMetric] = useState(null)
  const [groupName, setGroupName] = useState(null)

  const matchMetric = (group, metrics) => {
    metrics.forEach( candidate => {
      if (props.metric && props.metric === candidate.name) {
        setMetric(candidate)
        setGroupName(group)
        return
      }
      if (props.metricCode && props.metricCode === candidate.code) {
        setMetric(candidate)
        setGroupName(group)
      }
    })
  }

  useEffect(() => {
    let url = sourceURL(props.rootUrl, props.geoLocationType, props.geoLocation, props.year, props.report, props.selectLocationRegion);
    fetch(url)
      .then(response => response.json())
      .then(data => {
        data.data.metric_groups.forEach(group => {
          matchMetric(group.name, group.metrics)
        })
      })
  }, [props]);


  return (
    <div className="mado-dashboard__metric-group mado-dashboard__metric-group-silent">
      <div className="mado-metrics">
        { metric && 
          <Metric metricData={metric} loading={false} groupName={groupName} expanded />
        }
      </div>
    </div>
  );
}

export default MetricView;

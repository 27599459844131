import React from 'react';

function ErrorMessage() {
  return(
    <div>
      Sorry, there was a problem retrieving MN Goal data.
    </div>
  )
}

export default ErrorMessage;

import React, { useState, useContext } from 'react';
import { unitValue } from '../helpers/formatters';
import Chart from './Chart';
import ClickOutsideDetector from './ClickOutsideDetector';
import Trend from './Trend';
import ThemeContext from '../context/Theme';

export default function Metric(props) {
  const theme = useContext(ThemeContext)
  
  const [displayExpanded, setDisplayExpanded] = useState(false)
  const showExpanded = (props.expanded || displayExpanded)

  const displayClass = showExpanded ? "mado-metric-expanded" : ""
  const hasDataHistory = props.metricData.measurements?.length && props.metricData.measurements?.length > 1
  const ytitle = props.metricData.y_label || props.metricData.unit || ""
  const expandClass = showExpanded ? 'icon-fullscreen-exit' : 'icon-stats-dots'
  const label = props.metricData.local_label || props.metricData.label
  const description = props.metricData.local_description || props.metricData.description
  const url = props.metricData.local_url || props.metricData.url
  const goalCodeIcon = `icon-${theme}_${props.metricData.code} icon-${theme}_${props.groupName?.toLowerCase()}`

  const classNames = [].concat(displayClass).concat(props.metricData.local_class_names)

  const toggleExpandCollapse = (event) => {
    event.preventDefault()
    setDisplayExpanded(!displayExpanded)
  }


  const chartMax = () => {
    // if the max value for a percentage is under 100, then 100 is the max
    let max = Math.max.apply(null, props.metricData.measurements.map((m) => m.value))
    if (props.metricData.suffix === "%" && max <= 100) {
      return 100
    }
    // otherwise, add 15% and round up to the nearest 10
    return Math.ceil((max + (max * .15)) / 10) * 10
  }

  const visible = !!!props.loading
  const value = unitValue(props.metricData.measurements, props.metricData.unit_size, props.metricData.precision)

  return(      
    <ClickOutsideDetector
      listen
      onClickOutside={() => { setDisplayExpanded(false) }}
    >
      <div className={`mado-metric ${classNames.join(" ")} metric-code-${props.metricData.code}`}>
        <div className="mado-metric__header">
          <div>
            <div className={`mado-metric__header__icon ${goalCodeIcon}`}></div>
            <div className="mado-metric__header__heading">
              { visible && label }
            </div>
          </div>
          { !props.expanded &&
            <div className="mado-metric__header__expand">
              { visible && hasDataHistory &&
                <a href="#" onClick={toggleExpandCollapse} title={displayExpanded ? 'close' : 'Historic Data'}>
                  <span className={expandClass} />
                </a>
              }
            </div>
          }
        </div>
        { (!showExpanded || !hasDataHistory) &&
          <div className="mado-metric__primary">
            <div className="mado-metric__primary__value">
              {visible && props.metricData.prefix + value + props.metricData.suffix}
            </div>
            <div className="mado-metric__primary__unit">
              {visible && props.metricData.unit} &nbsp;
            </div>
          </div>
        }
        { showExpanded && hasDataHistory &&
          <div className="mado-metric__chart">
            <Chart data={props.metricData.measurements} ytitle={ytitle} max={chartMax()} />
          </div>
        }
        <div className="mado-metric__goal">
          { props.metricData.goal && 
            <p>Goal:</p>
          }
          { visible && props.metricData.goal }
          { visible && description && 
            <p>
              { description }
            </p>
          }
        </div>

        { props.metricData.trend_goal && hasDataHistory &&
          <div className="mado-metric__trend">
            <Trend data={props.metricData.measurements} trendGoal={props.metricData.trend_goal} />
          </div>
        }

        <div className="mado-metric__footer">
          { visible && url &&
            <a href={url} className="mado-metric__button">Learn More</a>
          }
        </div>
      </div>
    </ClickOutsideDetector>
  )

}
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'

const element = document.getElementById('mado-dashboard')
const geoLocationType = element.getAttribute('data-geo-location-type')
const geoLocation = element.getAttribute('data-geo-location')
const selectLocation = element.hasAttribute('data-select-location')
const selectLocationRegion = element.getAttribute('data-select-location')
const report = element.getAttribute('data-report') || 'mado'
const classNames = element.getAttribute('data-style')
const metric = element.getAttribute('data-metric')
const metricCode = element.getAttribute('data-metric-code')
const metricGroup = element.getAttribute('data-metric-group')

let year;
if (element.hasAttribute('data-year')) {
  year = element.getAttribute('data-year')
} else {
  year = "historic";
}

// const rootUrl = element.getAttribute('data-root-url');
// let rootUrl = "http://staging.mngoals.org/api/v1/metrics/";
let rootUrl;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  rootUrl = "http://mado.loc:3000/api/v1/metrics";
} else {
  rootUrl = "https://api.mngoals.org/api/v1/metrics";
}
rootUrl = "https://api.mngoals.org/api/v1/metrics";
ReactDOM.render(
  <App rootUrl={rootUrl} 
    geoLocation={geoLocation} 
    geoLocationType={geoLocationType}
    selectLocation={selectLocation}   
    selectLocationRegion={selectLocationRegion}
    year={year}
    report={report}
    classNames={classNames}
    metric={metric}
    metricCode={metricCode}
    metricGroup={metricGroup}
    />,
  element
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from 'react';
import { LineChart } from 'react-chartkick'
import 'chartkick/chart.js'

export default function Chart(props) {

  const data = props.data.sort((a, b) => a.year - b.year).map( (d) => {
    return [String(d.year), d.value]
  })

  return (
    <div className="metric-chart">
      <LineChart data={data} height="200px" ytitle={props.ytitle} xtitle="Year" max={props.max} />
    </div>
  );
}

import React from 'react';
import Select from 'react-select';
import Location from '../helpers/locations';

function SelectLocation(props) {
  
  function options(regions) {
    let stateOption = {
      value: Location.pack('state', 'Minnesota'),
      label: "State Wide"
    }
    let countyOptions = regions.map(region => { 
      let regionOptions = [
        {
          value: Location.pack(region.geo_location_type, region.geo_location),
          label: "All " + region.name + " Counties"
        }
      ]
      region.counties.sort().map(county => {
        regionOptions.push(
          {
            value: Location.pack(county.geo_location_type, county.geo_location),
            label: county.name
          }
        )
        return true;
      });
      return {label: region.name, options: regionOptions}
    })
    if (props.stateOption) return [stateOption, ...countyOptions]
    return countyOptions
  }

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4px 4px 2px 4px',
    color: '#333333',
    fontWeight: 'bold'
  };
  const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
  };
  const formatGroupLabel = data => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length - 1} Counties</span>
    </div>
  );

  return(
    <Select
      options={options(props.locations)}
      placeholder="See Other Areas..."
      formatGroupLabel={formatGroupLabel}
      className='select-location-container' 
      classNamePrefix="select-location"
      onChange={props.updateLocationFunction}
    />
  )
}

export default SelectLocation;
import React, { createContext } from 'react';
import Dashboard from './Dashboard';
import MetricView from './MetricView';
import ThemeContext from '../context/Theme';
import '../css/App.scss';

function App(props) {

  const classNames = [props.report, props.classNames]
  
  if (props.metric || props.metricCode) {
    return(
      <div className={`kt-dashboard-app ${classNames.join(" ")}`}>
        <ThemeContext.Provider value={props.report}>
          <MetricView {...props} />
        </ThemeContext.Provider>
      </div>
    )
  }

  return (
    <div className={`kt-dashboard-app ${classNames.join(" ")}`}>
      <ThemeContext.Provider value={props.report}>
        <Dashboard {...props} />
      </ThemeContext.Provider>
    </div>
  );
}

export default App;

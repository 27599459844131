import React from 'react';
import { describeTrend } from '../helpers/trends'

function Trend(props) {

  const historicData = props.data.sort((a, b) => a.year - b.year).map((d) => {
    return parseFloat(d.value)
  })

  const description = describeTrend(historicData, props.trendGoal)

  return (
    <>
      { description && 
        <p>
          { description }
        </p>
      }
    </>
  );
}

export default Trend;

import React, {Component} from 'react';
import Metric from './Metric';
import {parameterizeString} from '../helpers/formatters';
import {compareByName} from '../helpers/comparisons';

export default class MetricGroup extends Component {

  render() {

    let metrics = this.props.groupData.metrics.sort(compareByName).map(
      (metric, index) => {
        if (metric.measurements.length == 0) return;

        return(
          <div className="mado-metric-placeholder" key={"placeholder-" + index}>
            <Metric key={index} metricData={metric} loading={this.props.loading} groupName={this.props.groupData.name} />
          </div>
        )
      }
    )

    return(
      <div className={"mado-dashboard__metric-group " + parameterizeString(this.props.groupData.name)}>
        <h3 className="group-title">
          { this.props.groupData.name } 
          { this.props.groupData.url  && 
            <small>
              <a href="https://www.wealthworks.org/basics/explore-regional-wealth-building/wealth-eight-capitals">(Learn About This Topic)</a>
            </small>
          }
        </h3>
        <div className="mado-metrics">
          {metrics}
        </div>
      </div>
    )
  }

}